import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { Listings } from '@listings/models/listing/listings';
import { ListingsMediaFromApi } from '@listings/models/listing/listings-media-from-api';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import * as listingActions from '@listings/store/actions/listings.actions';
import * as recentlyViewedListingsActions from '@listings/store/actions/recently-viewed-listings.actions';
import * as onMarketActions from '@on-market/store/actions/on-market.actions';
import * as listingActivityActions from '../actions/listing-activity.actions';
import { ListingActivityId } from '@listings/models/listing/listing-activity-id';
import { ListingPriceChange } from '@listings/models/listing/listing-price-change';
import * as listingPriceChangesActions from '../actions/listing-price-changes.actions';
import { NewMatch } from '@listings/models/listing/new-match';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';
import * as newMatchesActions from '../actions/new-matches.actions';
import { ListingCardInfo } from '@listings/models/listing/listing-card-info';
import { ListingDetails } from '@listings/models/listing/listing-details';

@Injectable({ providedIn: 'root' })
export class ListingApiService {

    constructor(private readonly http: ApiHttpClient) { }

    public loadListing(listingId: string): Observable<Action> {
        return this.http
            .get(`listing/${listingId}`)
            .pipe(
                switchMap((response: ApiDataResult<{ details: ListingDetails, listingCard: ListingCardInfo }>) => {
                    const { listingCard, details } = response.result;

                    return [
                        listingActions.loadListingSuccess({ listing: listingCard, listingId }),
                        listingActions.loadListingsDetailsSuccess({ listingDetails: { [listingCard.hashCode]: details } }),
                    ];
                }),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadListingFailed(errorResponse.error as ApiError)))
            );
    }

    public loadListingsDetails(hashCodes: number[]): Observable<Action> {
        return this.http
            .post('listing/details', { hashCodes })
            .pipe(
                map((response: ApiDataResult<Record<number, ListingDetails>>) => listingActions.loadListingsDetailsSuccess({ listingDetails: response.result })),
                catchError(() => of(listingActions.loadListingsDetailsFailed()))
            );
    }

    public loadListingsByHashCodes(hashCodes: number[]): Observable<Action> {
        return this.http.post('listing/listings-by-hash-codes', { hashCodes }).pipe(
            switchMap((response: ApiDataResult<ListingCardInfo[]>) => response.error != null
                ? [listingActions.loadListinsgByHashCodesFailed(response.error)]
                : [
                    listingActions.loadListinsgByHashCodesSuccess({ loadedListings: response.result, listingsToDeleteIds: [] }),
                    listingActions.loadListingsMedia({ listingIds: response.result.map(x => x.id) }),
                    listingPriceChangesActions.loadListingsPriceChanges({ hashCodes }),
                ]
            ),
            catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadListingFailed(errorResponse.error as ApiError)))
        );
    }

    public loadCustomerListings(): Observable<Action> {
        return this.http
            .get('listing/all')
            .pipe(
                switchMap((response: ApiDataResult<Listings>) => {
                    const { ids: listingIds, hashCodes } = Object.values(response.result).reduce(
                        (acc, { id, hashCode }) => ({ ids: [...acc.ids, id], hashCodes: [...acc.hashCodes, hashCode] }),
                        { ids: [], hashCodes: [] } as { ids: string[], hashCodes: number[] }
                    );

                    return [
                        listingActions.loadCustomerListingsSuccess({ listings: response.result }),
                        listingActions.loadListingsMedia({ listingIds }),
                        listingPriceChangesActions.loadListingsPriceChanges({ hashCodes }),
                    ];
                })
            );
    }

    public loadListingsMedia(ids: string[]): Observable<Action> {
        return this.http
            .post('listing/listings-media', { listingIds: ids })
            .pipe(
                map((response: ApiDataResult<ListingsMediaFromApi>) => {
                    return response.error != null
                        ? listingActions.loadListingsMediaFailed(response.error)
                        : listingActions.loadListingsMediaSuccess({ media: response.result });
                }),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadListingsMediaFailed(errorResponse.error as ApiError)))
            );
    }

    public loadRecentlyViewedListings(countToLoad: number): Observable<Action> {
        return this.http
            .get(`analytics/activities/recently-viewed?countToLoad=${countToLoad}`)
            .pipe(
                map((response: ApiDataResult<ListingCardInfo[]>) => {
                    return response.error != null
                        ? recentlyViewedListingsActions.loadRecentlyViewedListingFailed({ error: response.error })
                        : recentlyViewedListingsActions.loadRecentlyViewedListingSuccess({ recentlyViewedlistings: response.result });
                }),
                catchError((errorResponse: HttpErrorResponse) => of(recentlyViewedListingsActions.loadRecentlyViewedListingFailed({ error: errorResponse.error as ApiError })))
            );
    }

    public loadMarketListings(searchOptions: ListingSearchOptions): Observable<Action> {
        return this.http
            .post('listing/searchListings', searchOptions)
            .pipe(
                switchMap((listings: Listings) => {
                    const hashCodes = Object.values(listings).map(x => x.hashCode);

                    return [
                        listingActions.loadMarketListingsSuccess({ listings }),
                        onMarketActions.loadSuccess({ searchOptions }),
                        listingPriceChangesActions.loadListingsPriceChanges({ hashCodes }),
                    ];
                }),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.loadMarketListingsFailed(errorResponse.error as ApiError)))
            );
    }

    public setListingsActivity(params: SetListingActivityRequest, customerId: number, agentId: number): Observable<Action> {
        const listingIdPinOwnerIdMap = {};

        params.listingCandidates.forEach(candidate => listingIdPinOwnerIdMap[candidate.id] = candidate.pinOwnerAgentId);

        return this.http.post('listing/set-listing-activity', {
            listingIdPinOwnerIdMap,
            listingIdsWithChangedActivity: params.listingCandidates.filter(candidate => candidate.activities.length > 0).map(candidate => candidate.id),
            activityId: params.activity.id,
            notifyRequired: params.notifyRequired,
        }).pipe(
            map((response: ApiDataResult<ListingActivityId[]>) => {
                return response.error != null
                    ? listingActivityActions.setListingsActivityFailed({ error: response.error, request: params, customerId, agentId })
                    : listingActivityActions.setListingsActivitySuccess({ request: params, activitiesSet: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) => of(listingActivityActions.setListingsActivityFailed({ error: errorResponse.error, request: params, customerId, agentId })))
        );
    }

    public markAsViewed(listingId: string, listingHashCode: number, pinOwnerAgentId: number): Observable<Action> {
        return this.http
            .post('listing/markAsViewed', { listingId, pinOwnerAgentId })
            .pipe(
                map(listingActions.markAsViewedSuccess),
                catchError((errorResponse: HttpErrorResponse) => of(listingActions.markAsViewedFailed(errorResponse.error as ApiError, listingHashCode)))
            );
    }

    public softDelete(
        listingIds: string[],
        listings: Listings,
        listingsActivities: Record<number, ListingsActivityInfo>,
        listingsNewMatches: Record<number, NewMatch[]>
    ): Observable<Action> {
        return this.http
            .post('listing/soft-delete', { listingIds })
            .pipe(
                map((response: ApiDataResult<number>) => {
                    return response.error != null
                        ? listingActions.softDeleteFailed({ error: response.error, listings, listingsActivities, listingsNewMatches })
                        : listingActions.softDeleteSuccess({ listingIds, listings, listingsActivities, listingsNewMatches });
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return of(listingActions.softDeleteFailed({ error: errorResponse.error as ApiError, listings, listingsActivities, listingsNewMatches }));
                })
            );
    }

    public restore(
        listingsIds: string[],
        listings: Listings,
        listingsActivities: Record<number, ListingsActivityInfo>,
        listingsNewMatches: Record<number, NewMatch[]>
    ): Observable<Action> {
        return this.http
            .post('listing/restore', { listingsIds })
            .pipe(
                map((response: ApiDataResult<number>) => {
                    return response.error != null
                        ? listingActions.restoreFailed({ error: response.error, listings, listingsActivities, listingsNewMatches })
                        : listingActions.restoreSuccess({ listingIds: listingsIds });
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return of(listingActions.restoreFailed({ error: errorResponse.error as ApiError, listings, listingsActivities, listingsNewMatches }));
                })
            );
    }

    public hardDelete(
        listingsIds: string[],
        listings: Listings,
        listingsActivities: Record<number, ListingsActivityInfo>,
        listingsNewMatches: Record<number, NewMatch[]>
    ): Observable<Action> {
        return this.http
            .post('listing/hard-delete', { listingsIds })
            .pipe(
                map((response: ApiDataResult<number>) => {
                    const listingsHashCodes = listingsIds.map(id => listings[id].hashCode);

                    return response.error != null
                        ? listingActions.hardDeleteFailed({ error: response.error, listings, listingsActivities, listingsNewMatches })
                        : listingActions.hardDeleteSuccess({ listingsHashCodes });
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return of(listingActions.hardDeleteFailed({ error: errorResponse.error as ApiError, listings, listingsActivities, listingsNewMatches }));
                })
            );
    }

    public getLastListingPriceChanges(hashCodes: number[]): Observable<Action> {
        return this.http
            .post('listing/price-changes', { hashCodes })
            .pipe(
                map((response: ApiDataResult<Record<number, ListingPriceChange>>) => {
                    return response.error != null
                        ? listingPriceChangesActions.loadListingsPriceChangesFailed()
                        : listingPriceChangesActions.loadListingsPriceChangesSuccess({ priceChanges: response.result });
                }),
                catchError(() => {
                    return of(listingPriceChangesActions.loadListingsPriceChangesFailed());
                })
            );
    }

    public loadListingsNewMatches(hashCodes: number[], shouldSetLoaded: boolean): Observable<Action> {
        return this.http
            .post('listing/new-matches', { hashCodes })
            .pipe(
                map((response: ApiDataResult<Record<number, NewMatch[]>>) => {
                    return response.error != null
                        ? newMatchesActions.loadListingsNewMatchesFailed()
                        : newMatchesActions.loadListingsNewMatchesSuccess({ hashCodes, newMatches: response.result, shouldSetLoaded });
                }),
                catchError(() => of(listingActivityActions.loadListingsActivitiesFailed()))
            );
    }

    public loadListingsActivities(hashCodes: number[], shouldSetLoaded: boolean): Observable<Action> {
        return this.http
            .post('listing/activities', { hashCodes })
            .pipe(
                map((response: ApiDataResult<Record<number, ListingsActivityInfo>>) => {
                    return response.error != null
                        ? listingActivityActions.loadListingsActivitiesFailed()
                        : listingActivityActions.loadListingsActivitiesSuccess({ activities: response.result, shouldSetLoaded });
                }),
                catchError(() => of(listingPriceChangesActions.loadListingsPriceChangesFailed()))
            );
    }
}