import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { ListingCategoryTexts } from '@listings/enums/listing-category-texts.enum';
import { ListingOwnershipTexts } from '@listings/enums/listing-ownership-texts.enum';
import { ListingTypeTexts } from '@listings/enums/listing-type-texts.enum';
import { ListingCardInfo } from '@listings/models/listing/listing-card-info';
import { ListingDetails } from '@listings/models/listing/listing-details';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { Listings } from '@listings/models/listing/listings';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';
import { NewMatch } from '@listings/models/listing/new-match';
import { ShowAddressOption } from '@shared-listing-details/enums/show-address-option.enums';

export class ListingHelperService {

    public static getListingFullAddress(listing: ListingCardInfo, hideOpenRentals: boolean, showAddressOption: ShowAddressOption = null): string {
        if (hideOpenRentals && ListingHelperService.isOpenRental(listing)) {
            return listing.addressInfo.neighborhood;
        }

        if (showAddressOption != null && showAddressOption === ShowAddressOption.street) {
            return listing.addressInfo.address.slice(listing.addressInfo.address.indexOf(' '));
        }

        let { address } = listing.addressInfo;

        address += (listing.addressInfo.unitNumber.toUpperCase() !== 'TOWNHOUSE' ? ', ' + listing.addressInfo.unitNumber : '');

        return address;
    }

    public static mapActivityToListingActivityType(activityId: number): ListingActivityType {
        const map = new Map<number, ListingActivityType>([
            [ListingActivityConstants.PickListed.id, ListingActivityType.PickListed],
            [ListingActivityConstants.Liked.id, ListingActivityType.Liked],
            [ListingActivityConstants.Disliked.id, ListingActivityType.Disliked],
            [ListingActivityConstants.Shown.id, ListingActivityType.Shown],
        ]);

        return map.has(activityId) ? map.get(activityId) : null;
    }

    public static getListingOwnershipType(listing: ListingCardInfo): ListingOwnershipTexts {
        if (listing.ownershipType == null) {
            return null;
        }

        switch (listing.ownershipType.toUpperCase()) {
            case ListingOwnershipTexts.Condo:
                return ListingOwnershipTexts.Condo;
            case ListingOwnershipTexts.Condop:
                return ListingOwnershipTexts.Condop;
            case ListingOwnershipTexts.Coop:
                return ListingOwnershipTexts.Coop;
            case ListingOwnershipTexts.Rental:
                return ListingOwnershipTexts.Rental;
            case ListingOwnershipTexts.Townhouse:
                return ListingOwnershipTexts.Townhouse;
            default:
                return null;
        }
    }

    public static getListingCategory(listingCategory: string): ListingCategoryTexts {
        switch (listingCategory.toUpperCase()) {
            case ListingCategoryTexts.Rental:
                return ListingCategoryTexts.Rental;
            case ListingCategoryTexts.Sales:
                return ListingCategoryTexts.Sales;
            default:
                return null;
        }
    }

    public static isOpenRental(listing: ListingCardInfo): boolean {
        return listing.category.toUpperCase() === ListingCategoryTexts.Rental && listing.type.toLowerCase() === ListingTypeTexts.Open;
    }

    public static getFurnishedValue(listing: ListingDetails): string {
        if (!Boolean(listing.unit?.furnished)) {
            return '';
        }

        const value = listing.unit?.furnished?.toUpperCase();

        return value === 'Y'
            ? 'LISTING_FIELDS.ATTRIBUTE_VALUES.YES'
            : value === 'N'
                ? 'LISTING_FIELDS.ATTRIBUTE_VALUES.NO'
                : 'LISTING_FIELDS.ATTRIBUTE_VALUES.UNKNOWN';
    }

    public static mapToListings(commonListings: ListingCardInfo[]): Listings {
        return commonListings.reduce((all, listing) => ({ ...all, [listing.id]: listing }), {} as Listings);
    }

    public static createListingInfo(
        listing: ListingCardInfo,
        listingsActivities: Record<number, ListingsActivityInfo>,
        listingsNewMatches: Record<number, NewMatch[]>,
        agentsIds: Set<number>,
        agentId: number
    ): ListingInfo {
        const activityInfo = listingsActivities[listing.hashCode];
        const newMatches = listingsNewMatches[listing.hashCode] ?? [];
        const firstNewMatch = newMatches.sort((a, b) => new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime())?.[0];
        const customerAssignedDate = activityInfo?.createDate ?? firstNewMatch?.updateDate;

        return {
            ...listing,
            isNewMatch: newMatches.length > 0,
            customerAssignedDate: customerAssignedDate != null ? new Date(customerAssignedDate) : new Date(),
            pinOwnerAgentId: activityInfo?.pinOwnerAgentId ?? agentId,
            listingOwnerId: activityInfo?.createId ?? firstNewMatch?.createId,
            isAddedByAgent: activityInfo?.isAddedByAgent,
            isNewViewed: activityInfo == null || !(!activityInfo.isNewViewed && agentsIds.has(activityInfo.createId)),
            isDeleted: activityInfo?.isDeleted,
            activities: activityInfo?.activities ?? [],
            newMatches
        };
    }
}